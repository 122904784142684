import * as React from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout/layout"
import {
  MouseParallaxChild,
  MouseParallaxContainer
} from "react-parallax-mouse"
import Settings from "../settings.js"

const IndexPage = () => {
  return (
    <Layout>
      <Helmet>
        <body className="-green homepage" />
      </Helmet>
      <MouseParallaxContainer
        enabled={Settings.parallaxOn}
        className="screen homepage"
      >
        <h1
          className="homepage__title"
          data-type="animated-show"
          data-animation="translateY"
          data-duration="0.5"
          data-delay="1"
        >
          <span className="desktop">
            What type of crypto<br />
            trader are you?
          </span>
          <span className="mobile">
            What type of crypto trader are you?
          </span>
        </h1>
        <h3
          className="homepage__subtitle"
          data-type="animated-show"
          data-animation="translateY"
          data-duration="0.5"
          data-delay="2.5"
        >
          Test your skill
        </h3>
        <div
          data-to="/question/1"
          className="ui-button-shadow -same-mobile-view homepage__button"
          data-type="animated-show"
          data-animation="translateY"
          data-duration="0.5"
          data-delay="4"
        ><span>let's go</span></div>
        <div
          className="homepage__footer"
          data-type="animated-show"
          data-duration="0.5"
          data-delay="5"
        >
          <p>Want to play silently? Make sure your sound is off.</p>
          <div className="ui-music-button -off"></div>
        </div>
        <MouseParallaxChild
          className="screen__map"
          factorX={0.04}
          factorY={0.04}
        >
          <div
            data-type="animated-show"
            data-duration="0.1"
            data-delay="0.05"
          />
        </MouseParallaxChild>
        <MouseParallaxChild
          className="screen__washington"
          factorX={0.08}
          factorY={0.08}
        >
          <div
            className="-top"
            data-type="animated-show"
            data-animation="scaleDown"
            data-duration="0.1"
            data-delay="0.1"
          />
        </MouseParallaxChild>
        <MouseParallaxChild
          className="screen__dots"
          factorX={0.12}
          factorY={0.12}
        >
          <div
            className="-left"
            data-type="animated-show"
            data-duration="0.1"
            data-delay="0.15"
          />
        </MouseParallaxChild>
        <MouseParallaxChild
          className="screen__dots"
          factorX={0.4}
          factorY={0.4}
        >
          <div
            className="-right"
            data-type="animated-show"
            data-duration="0.1"
            data-delay="0.55"
          />
        </MouseParallaxChild>
        <MouseParallaxChild
          className="screen__chart"
          factorX={0.16}
          factorY={0.16}
        >
          <div
            className="-white -down"
            data-type="animated-show"
            data-animation="scaleDown"
            data-duration="0.1"
            data-delay="0.2"
          />
        </MouseParallaxChild>
        <MouseParallaxChild
          className="screen__chart"
          factorX={0.48}
          factorY={0.48}
        >
          <div
            className="-white -up"
            data-type="animated-show"
            data-animation="scaleUp"
            data-duration="0.1"
            data-delay="0.6"
          />
        </MouseParallaxChild>
        <MouseParallaxChild
          className="screen__bitcoin"
          factorX={0.24}
          factorY={0.24}
        >
          <div
            className="-red"
            data-type="animated-show"
            data-animation="scaleUp"
            data-duration="0.1"
            data-delay="0.3"
          />
        </MouseParallaxChild>
      </MouseParallaxContainer>
    </Layout>
  )
}

export default IndexPage
